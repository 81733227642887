import React, {  useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Female from "../components/img/Female.jpg";

const FemaleInfertilityPage = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  
  useEffect(() => {
    if (inView) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: { type: 'spring', stiffness: 85, duration: 2},
      });
    }
  }, [controls, inView]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <motion.img 
            src={Female} 
            className="img-fluid" 
            alt="Your Image" 
            initial={{ x: '-100vw' }}
            animate={controls}
            ref={ref}
          />
        </div>
        <div className="col-md-6">
          <motion.div 
            className="content"
            initial={{ opacity: 0 }}
            animate={controls}
            ref={ref}
          >
            <br />
            {/* <p className='text-start ms-2'>WE OFFERS HOLISTIC & INTEGRATED TREATMENTS</p> */}
            <h1 className='text-start ms-2'>Female Infertility</h1>
            <div className="yellow-line ms-2"></div> {/* Yellow underline */} <br />

            <p className='text-start ms-2'>Female infertility is the inability of a female to conceive within a year of regular unprotected sex. It is caused by irregular ovulation, obstructed fallopian tubes, or hormonal imbalance. Diagnosis begins with examinations to determine the cause of infertility. Treatments may vary from drugs to surgery and even assisted reproduction techniques such as IVF.</p>
            <p className='text-start ms-2'>In most cases of female infertility, treatment must be addressed based on the understood root cause. Be it irregular ovulation or some structural abnormality, it has to be treated accordingly.</p>
            {/* <button type="button" className="btn btn-warning ms-2">About Us</button> */}
            {/* Add more content here */}
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default FemaleInfertilityPage;
