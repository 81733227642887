import React from 'react'
import misscaringBanner from "../components/img/misscaringBanner.jpg";
const MiscarringBanner = () => {
  return (
    <div>
    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={misscaringBanner} className="d-block w-100 carousel-image" alt="..." />
        </div>
      </div>
    </div>
  </div>
  )
}

export default MiscarringBanner
