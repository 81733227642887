// YoutubeEmbed.js

import React from 'react';
import './Youtube.css';
import { useInView } from 'react-intersection-observer';

const Youtube = ({ embedId }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5 // Adjust this threshold as needed
    });

    return (
        <div ref={ref} className={`video-responsive ${inView ? 'animated' : ''}`}>
            <div className="animated-text">
                <p style={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}>OUR 3 PILLARS</p>
                <h3 style={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}><b>TRUST, TRANSPARENCY & RESULTS</b></h3>
                <p className=" ms-2" style={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}>Best IVF Centre in Agra – This is What Excellence Looks Like</p>
            </div>
            <br /><br />
            <iframe
                src={`https://www.youtube.com/embed/${embedId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
            <br /><br />
            <div className="wpb_wrapper">
                <h5
                    style={{ color: "#555555", textAlign: "left" }}
                    className="vc_custom_heading font400 text-start  ms-5"
                >
                    <br /><br />
                    ADVANCED TREATMENT &amp; CARE SOLUTIONS
                </h5>
                <div
                    id="la_heading_66448fbf5e63f"
                    className="la-headings text-left spacer-position-bottom"
                >
                    <h2
                        className="js-el heading-tag la-unit-responsive text-start  ms-5"
                        style={{ color: "#541936" }}
                        data-la_component="UnitResponsive"
                        data-el_target="#la_heading_66448fbf5e63f .heading-tag"
                        data-el_media_sizes='{"font-size":"xs:26px;","line-height":""}'
                    >
                        <b>Our Departments</b>
                    </h2>
                    <div
                        className="js-el subheading-tag la-unit-responsive text-start  ms-5"
                        data-la_component="UnitResponsive"
                        data-el_target="#la_heading_66448fbf5e63f .subheading-tag"
                        data-el_media_sizes='{"font-size":"lg:18px;","line-height":""}'
                    >
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                <b>At Dr.Vaishali Tandon Clinic, we care about our patients well being and support
                                them.</b>
                                <br /><br />
                            </span>
                        </p>
                        <div className="yellow-line-AT"></div> {/* Yellow underline */}
                    </div>
                    <div className="la-separator" style={{ height: 1 }}>
                        <span
                            className="la-line js-el la-unit-responsive"
                            style={{
                                borderStyle: "solid",
                                borderWidth: "1px 0 0",
                                borderColor: ""
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Youtube;
