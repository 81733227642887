import React from 'react'
import LowOvarianReserveBanner from './LowOvarianReserveBanner'
import LowOvarianReservePage from './LowOvarianReservePage'

const LowOvarianReserve = () => {
  return (
    <div>
      <LowOvarianReserveBanner/><br /><br />
      <LowOvarianReservePage/><br /><br /><br />
    </div>
  )
}

export default LowOvarianReserve
