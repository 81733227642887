import React from 'react'
import ThirdPartyReproductionBanner from './ThirdPartyReproductionBanner'
import ThirdPartyReproductionPage from './ThirdPartyReproductionPage'

const ThirdPartyReproduction = () => {
  return (
    <div>
      <ThirdPartyReproductionBanner/><br /><br />
      <ThirdPartyReproductionPage/><br /><br /><br />
    </div>
  )
}

export default ThirdPartyReproduction
