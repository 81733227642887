import React, { useState } from 'react';
import Tondonlogo from "../components/img/Tondonlogo.png";
import './Navbar.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import About from './About';
import Home from './Home';
import Testimonials from './Testimonials';
import Videos from './Videos';
import Blogs from './Blogs';
import ContactUs from './ContactUs';
import InVitroFertilization from '../droppages/InVitroFertilization';
import HighRiskPregnancy from '../droppages/HighRiskPregnancy';
import OvulationInduction from '../droppages/OvulationInduction';
import MaleInfertility from '../droppages/MaleInfertility';
import FemaleInfertility from '../droppages/FemaleInfertility';
import FertilityEnhancingSurgeries from '../droppages/FertilityEnhancingSurgeries';
import IuiTreatment from '../droppages/IuiTreatment';
import Cryopreservation from '../droppages/Cryopreservation';
import ThirdPartyReproduction from '../droppages/ThirdPartyReproduction';
import IvfFailures from '../droppages/IvfFailures';
import FemaleInfertility2 from '../droppages/FemaleInfertility2';
import Pcos from '../droppages/Pcos';
import Endometriosis from '../droppages/Endometriosis';
import OverianCyst from '../droppages/OverianCyst';
import Miscarring from '../droppages/Miscarring';
import TubeBlock from '../droppages/TubeBlock';
import AdvancedAge from '../droppages/AdvancedAge';
import LowOvarianReserve from '../droppages/LowOvarianReserve';
import ScrollToTop from './ScrollToTop'; // Import ScrollToTop component

const Navbar = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const handleNavItemClick = () => setIsNavCollapsed(true);

  return (
    <Router>
      <div>
        <ScrollToTop /> {/* Add the ScrollToTop component here */}
        <header>
          <nav className="navbar navbar-expand-lg navbar-light bg-light py-0">
            <div className="container" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
              <Link className="navbar-brand" to="/" onClick={handleNavItemClick}>
                <span role="img" aria-label="Hello">
                  <img src={Tondonlogo} alt="" />
                </span>
              </Link>
              <button className="navbar-toggler" type="button" onClick={handleNavCollapse} aria-controls="navbarSupportedContent" aria-expanded={!isNavCollapsed} aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link className="nav-link active men-link ms-3" aria-current="page" to="/" onClick={handleNavItemClick} style={{color: '#603258'}}>Home</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link women-link ms-3" to="/about-us" onClick={handleNavItemClick} style={{color: '#603258'}}>About Us</Link>
                  </li>
                  <li className="nav-item nav ms-3">
                    <a className="nav-link nav-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{color: '#603258'}}>Treatment<svg
                      className="dropdown-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M6 9l6 6 6-6" />
                    </svg>
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><Link className="dropdown-item" to="/invitrofertilization" onClick={handleNavItemClick} style={{color: '#603258'}}>In Vitro Fertilization (IVF)</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/highriskpragnancy" onClick={handleNavItemClick} style={{color: '#603258'}}>High Risk Pregnancy</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/ovulationinduction" onClick={handleNavItemClick} style={{color: '#603258'}}>Ovulation Induction</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/maleinfertility" onClick={handleNavItemClick} style={{color: '#603258'}}>Male Infertility</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/femaleinfertility" onClick={handleNavItemClick} style={{color: '#603258'}}>Female Infertility</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/fertilityenhancingsurgeries" onClick={handleNavItemClick} style={{color: '#603258'}}>InFertility Enhancing Surgeries</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/intrauterineinseminationIUItreatment" onClick={handleNavItemClick} style={{color: '#603258'}}>Intrauterine Insemination (IUI) Treatment</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/fertilitycryopreservation" onClick={handleNavItemClick} style={{color: '#603258'}}>InFertility Cryopreservation</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/thirdpartyreproduction" onClick={handleNavItemClick} style={{color: '#603258'}}>Third Party Reproduction</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/ivffailures" onClick={handleNavItemClick} style={{color: '#603258'}}>IVF Failures</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item nav ms-3">
                    <a className="nav-link nav-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{color: '#603258'}}>Condition<svg
                      className="dropdown-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M6 9l6 6 6-6" />
                    </svg>
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><Link className="dropdown-item" to="/female-infertility" onClick={handleNavItemClick} style={{color: '#603258'}}>Female Infertility</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/pcos" onClick={handleNavItemClick} style={{color: '#603258'}}>PCOS</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/endometriosis/adenomyosis" onClick={handleNavItemClick} style={{color: '#603258'}}>Endometriosis/Adenomyosis</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/ovariancyst" onClick={handleNavItemClick} style={{color: '#603258'}}>Ovarian Cyst</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/recurrentpregnancyloss(miscarriage)" onClick={handleNavItemClick} style={{color: '#603258'}}>Recurrent Pregnancy Loss (Miscarriage)</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/fallopiantubeblock/tubolblock" onClick={handleNavItemClick} style={{color: '#603258'}}>Fallopian Tube Block/Tubol Block</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/advancedage" onClick={handleNavItemClick} style={{color: '#603258'}}>Advanced Age</Link></li>
                      <hr className="dropdown-divider" />
                      <li><Link className="dropdown-item" to="/lowovarianreserve" onClick={handleNavItemClick} style={{color: '#603258'}}>Low Ovarian Reserve</Link></li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link women-link ms-3" to="/testimonials" onClick={handleNavItemClick} style={{color: '#603258'}}>Testimonials</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link women-link ms-3" to="/videos" onClick={handleNavItemClick} style={{color: '#603258'}}>Videos</Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link className="nav-link women-link ms-3" to="/blogs" onClick={handleNavItemClick} style={{color: '#603258'}}>Blogs</Link>
                  </li> */}
                  <li className="nav-item">
                    <Link className="nav-link women-link ms-3" to="/contact-us" onClick={handleNavItemClick} style={{color: '#603258'}}>Contact Us</Link>
                  </li>
                  <button className="call-button">
                    <a rel="nofollow" className="component-target text-white" href="tel:+(91)-07060536628">
                      <i className="fa fa-phone"></i>
                      <span className="component-target-text">+(91)-07060536628</span>
                    </a>
                  </button>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        <div className="main-content"> {/* Added this wrapper div */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/invitrofertilization" element={<InVitroFertilization />} />
            <Route path="/highriskpragnancy" element={<HighRiskPregnancy />} />
            <Route path="/ovulationinduction" element={<OvulationInduction />} />
            <Route path="/maleinfertility" element={<MaleInfertility />} />
            <Route path="/femaleinfertility" element={<FemaleInfertility />} />
            <Route path="/fertilityenhancingsurgeries" element={<FertilityEnhancingSurgeries />} />
            <Route path="/intrauterineinseminationIUItreatment" element={<IuiTreatment />} />
            <Route path="/fertilitycryopreservation" element={<Cryopreservation />} />
            <Route path="/thirdpartyreproduction" element={<ThirdPartyReproduction />} />
            <Route path="/ivffailures" element={<IvfFailures />} />
            <Route path="/female-infertility" element={<FemaleInfertility2 />} />
            <Route path="/pcos" element={<Pcos />} />
            <Route path="/endometriosis/adenomyosis" element={<Endometriosis />} />
            <Route path="/ovariancyst" element={<OverianCyst />} />
            <Route path="/recurrentpregnancyloss(miscarriage)" element={<Miscarring />} />
            <Route path="/fallopiantubeblock/tubolblock" element={<TubeBlock />} />
            <Route path="/advancedage" element={<AdvancedAge />} />
            <Route path="/lowovarianreserve" element={<LowOvarianReserve />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/videos" element={<Videos />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default Navbar;
