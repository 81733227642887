import React from 'react'
import MiscarringBanner from './MiscarringBanner'
import MiscarringPage from './MiscarringPage'

const Miscarring = () => {
  return (
    <div>
      <MiscarringBanner/><br /><br />
      <MiscarringPage/><br /><br /><br />
    </div>
  )
}

export default Miscarring
