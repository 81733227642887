import React from 'react'
import femaleBanner from "../components/img/femaleBanner.avif";
const FemaleInfertilityBanner = () => {
  return (
    <div>
    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={femaleBanner} className="d-block w-100 carousel-image" alt="..." />
        </div>
      </div>
    </div>
  </div>
  )
}

export default FemaleInfertilityBanner
