import React from 'react'
import IVFFailbanner from "../components/img/IVFFailbanner.jpg";
const IvfFailuresBanner = () => {
  return (
    <div>
    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={IVFFailbanner} className="d-block w-100 carousel-image" alt="..." />
        </div>
      </div>
    </div>
  </div>
  )
}

export default IvfFailuresBanner
