import React, {  useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import IVF from "../components/img/IVF.jpg";

const InVitroFertilizationPage = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  
  useEffect(() => {
    if (inView) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: { type: 'spring', stiffness: 85, duration: 2},
      });
    }
  }, [controls, inView]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <motion.img 
            src={IVF} 
            className="img-fluid" 
            alt="Your Image" 
            initial={{ x: '-100vw' }}
            animate={controls}
            ref={ref}
          />
        </div>
        <div className="col-md-6">
          <motion.div 
            className="content"
            initial={{ opacity: 0 }}
            animate={controls}
            ref={ref}
          >
            <br />
            {/* <p className='text-start ms-2'>WE OFFERS HOLISTIC & INTEGRATED TREATMENTS</p> */}
            <h1 className='text-start ms-2'>In Vitro Fertilization (IVF)</h1>
            <div className="yellow-line ms-2"></div> {/* Yellow underline */} <br />

            <p className='text-start ms-2'>In vitro fertilization is a technique used to help childless couples have children. In the process, eggs and sperms are collected and fertilized in the laboratory.</p>
            <p className='text-start ms-2'>The resulting fertilized egg, or the embryo, is transferred to the uterus of the woman. It implants and initiates pregnancy there. IVF treats many cases like infertility, blockages in the fallopian tubes, endometriosis, and so on, regarding reproductive health issues.</p>
            {/* <button type="button" className="btn btn-warning ms-2">About Us</button> */}
            {/* Add more content here */}
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default InVitroFertilizationPage;
