import React from 'react'
import OvulationInductionBanner from './OvulationInductionBanner'
import OvulationInductionPage from './OvulationInductionPage'

const OvulationInduction = () => {
  return (
    <div>
      <OvulationInductionBanner/><br /><br />
      <OvulationInductionPage/><br /><br /><br />
    </div>
  )
}

export default OvulationInduction
