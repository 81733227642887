import React from 'react'
import IuiTreatmentBanner from './IuiTreatmentBanner'
import IuiTreatmentPage from './IuiTreatmentPage'

const IuiTreatment = () => {
  return (
    <div>
      <IuiTreatmentBanner/><br /><br />
      <IuiTreatmentPage/><br /><br /><br />
    </div>
  )
}

export default IuiTreatment
