import React from 'react'
import ContactForm from "./ContactForm";
import ContactFormBanner from './ContactFormBanner';
const ContactUs = () => {
  return (
    <div>
      <ContactFormBanner/>
      <ContactForm/>
    </div>
  )
}

export default ContactUs
