import React from 'react'
import Banner from './Banner'

import Youtube from './Youtube';
import FertilitySection from './FertilitySection';
// import DoctorsSection from './DoctorsSection';


import AboutUs from './AboutUs';
import Center from './Center';
import HomeStat from './HomeStat';
import HomeReview from './HomeReview';
// import Carousel from './Carousel';






const Home = () => {
  return (
    <div>
       
        <Banner/> 
        {/* <Carousel/> */}
        <HomeReview/>
        <Youtube embedId="o8qdP7Ra8sM"/>
        <FertilitySection/>
        {/* <DoctorsSection/>     */}
        <AboutUs/>  
        <br /><br />
        <hr />
       <Center/>
       <HomeStat/>
      
         
       
      
    </div>
  )
}

export default Home
