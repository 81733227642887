import React, {  useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Intrauterine from "../components/img/Intrauterine.jpg";

const IuiTreatmentPage = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  
  useEffect(() => {
    if (inView) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: { type: 'spring', stiffness: 85, duration: 2},
      });
    }
  }, [controls, inView]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <motion.img 
            src={Intrauterine} 
            className="img-fluid" 
            alt="Your Image" 
            initial={{ x: '-100vw' }}
            animate={controls}
            ref={ref}
          />
        </div>
        <div className="col-md-6">
          <motion.div 
            className="content"
            initial={{ opacity: 0 }}
            animate={controls}
            ref={ref}
          >
            <br />
            {/* <p className='text-start ms-2'>WE OFFERS HOLISTIC & INTEGRATED TREATMENTS</p> */}
            <h1 className='text-start ms-2' style={{ fontSize: '1.7rem' }}>Intrauterine Insemination (IUI) Treatment</h1>
            <div className="yellow-line ms-2"></div> {/* Yellow underline */} <br />

            <p className='text-start ms-2'>Intrauterine Insemination is a type of infertility treatment wherein the sperm is introduced into the uterus to increase the chances of getting pregnant. This technique is adopted either when unexplained infertility exists or when sperm faces problems in reaching the egg. Sperm is prepared for this procedure, after which a thin tube introduces it. While the technique is relatively not as invasive and expensive as other available options, success depends on various life factors, such as age and sperm quality.</p>
            {/* <button type="button" className="btn btn-warning ms-2">About Us</button> */}
            {/* Add more content here */}
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default IuiTreatmentPage;
