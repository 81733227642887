import React from 'react'
import TestimonialBanner from './img/Testimonials.jpg'; // Image ko import karein

const BlogBanner = () => {
  return (
    <div>
      <div
      className="banner"
      style={{
        backgroundImage: `url(${TestimonialBanner})`, // Imported image ka istemal karein
      }}
    >
      <div className="banner-content-B">
        <h1 className='aboutus-banner ms-5 text-center text-black'><b>Blogs
</b></h1>
      </div>
    </div>
    </div>
  )
}

export default BlogBanner
