import React from 'react'
import CryopreservationBanner from './CryopreservationBanner'
import CryopreservationPage from './CryopreservationPage'

const Cryopreservation = () => {
  return (
    <div>
      <CryopreservationBanner/><br /><br />
      <CryopreservationPage/><br /><br /><br />
    </div>
  )
}

export default Cryopreservation
