import React, {  useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import LowOvarian from "../components/img/LowOvarian.jpg";

const LowOvarianReservePage = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  
  useEffect(() => {
    if (inView) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: { type: 'spring', stiffness: 85, duration: 2},
      });
    }
  }, [controls, inView]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <motion.img 
            src={LowOvarian}
            className="img-fluid" 
            alt="Your Image" 
            initial={{ x: '-100vw' }}
            animate={controls}
            ref={ref}
          />
        </div>
        <div className="col-md-6">
          <motion.div 
            className="content"
            initial={{ opacity: 0 }}
            animate={controls}
            ref={ref}
          >
            <br />
            {/* <p className='text-start ms-2'>WE OFFERS HOLISTIC & INTEGRATED TREATMENTS</p> */}
            <h1 className='text-start ms-2'>Low Ovarian Reserve</h1>
            <div className="yellow-line ms-2"></div> {/* Yellow underline */} <br />

            <p className='text-start ms-2'>Low ovarian reserve is a condition whereby a woman's ovaries contain fewer eggs than would be expected at her age, hindering her from conception. The decline in the number of eggs is a part of the normal aging process; however, it can be accelerated by genetics or some medical treatments and/or certain health conditions. Testing for low ovarian reserve normally includes blood tests to measure hormones and imaging by ultrasound to count the number of follicles in the ovaries. However, although low ovarian reserve reduces infertility, some women still have a chance of becoming pregnant after undergoing infertility treatments or assisted methods like IVF.</p>
            {/* <p className='text-start ms-2'>Located in the prominent location of Noida, we are committed to providing compassionate and personalised fertility treatments.</p> */}
            {/* <button type="button" className="btn btn-warning ms-2">About Us</button> */}
            {/* Add more content here */}
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default LowOvarianReservePage;
