import React from 'react'
import MaleInfertilityBanner from './MaleInfertilityBanner'
import MaleInfertilityPage from './MaleInfertilityPage'

const MaleInfertility = () => {
  return (
    <div>
      <MaleInfertilityBanner/><br /><br />
      <MaleInfertilityPage/><br /><br /><br />
    </div>
  )
}

export default MaleInfertility
