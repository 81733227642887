import React, {  useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Recurrent from "../components/img/Recurrent.jpg";

const MiscarringPage = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  
  useEffect(() => {
    if (inView) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: { type: 'spring', stiffness: 85, duration: 2},
      });
    }
  }, [controls, inView]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <motion.img 
            src={Recurrent} 
            className="img-fluid" 
            alt="Your Image" 
            initial={{ x: '-100vw' }}
            animate={controls}
            ref={ref}
          />
        </div>
        <div className="col-md-6">
          <motion.div 
            className="content"
            initial={{ opacity: 0 }}
            animate={controls}
            ref={ref}
          >
            <br />
            {/* <p className='text-start ms-2'>WE OFFERS HOLISTIC & INTEGRATED TREATMENTS</p> */}
            <h1 className='text-start ms-2'>Recurrent Pregnancy Loss (Miscarriage)</h1>
            <div className="yellow-line ms-2"></div> {/* Yellow underline */} <br />

            <p className='text-start ms-2'>RPL, recurrent miscarriage, is an exasperating issue that epitomizes the loss of two consecutive pregnancies before the 20th week of pregnancy, which, if experienced, can be devastating to the person involved. This may relate to many other factors like chromosomal abnormalities, uterine factors, hormonal factors, and blood clotting disorders. In most instances, diagnosis is followed by extensive medical analyses in order to determine the cause, succeeded by treatment plans which may involve changes in lifestyle, medicinal treatment, or even assisted reproduction techniques. </p>
            {/* <p className='text-start ms-2'>Located in the prominent location of Noida, we are committed to providing compassionate and personalised fertility treatments.</p> */}
            {/* <button type="button" className="btn btn-warning ms-2">About Us</button> */}
            {/* Add more content here */}
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default MiscarringPage;
