import React from 'react'
import EnhancingBanner from "../components/img/EnhancingBanner.jpg";
const FertilityEnhancingSurgeriesBanner = () => {
  return (
    <div>
    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={EnhancingBanner} className="d-block w-100 carousel-image" alt="..." />
        </div>
      </div>
    </div>
  </div>
  )
}

export default FertilityEnhancingSurgeriesBanner
