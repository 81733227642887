import React from 'react'
import AdvancedAgeBanner from './AdvancedAgeBanner'
import AdvancedAgePage from './AdvancedAgePage'

const AdvancedAge = () => {
  return (
    <div>
      <AdvancedAgeBanner/><br /><br />
      <AdvancedAgePage/><br /><br /><br />
    </div>
  )
}

export default AdvancedAge
