import React from 'react';
import './TeamMember.css';
import { useInView } from 'react-intersection-observer';
import AboutDoctor1 from "./img/AboutDoctor1.jpg";
import AboutDoctor2 from "./img/AboutDoctor2.jpg";
import AboutDoctor3 from "./img/AboutDoctor3.jpg";

const TeamMember = () => {
  const { ref: card1Ref, inView: card1InView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: card2Ref, inView: card2InView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: card3Ref, inView: card3InView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className='Doctor-text-TM'>
      
      <h1 className='Doctor-text text-start ms-5'><b>Team Members</b></h1>
      <div className="yellow-line-TM ms-5 "></div> {/* Yellow underline */}
      
      <div className="container">
        <div className="row">
          <div ref={card1Ref} className={`col-lg-4 col-md-6 mb-4 card-TM-container ${card1InView ? 'animate' : ''}`}>
            <div className="card-TM">
              <img src={AboutDoctor1} className="card-img-top" alt="" />
              <div className="card-body">
                <h4><b>Dr. Vaishali Tandon</b></h4>
                <p><b>Director</b></p>
              </div>
            </div>
          </div>
          <div ref={card2Ref} className={`col-lg-4 col-md-6 mb-4 card-TM-container ${card2InView ? 'animate' : ''}`}>
            <div className="card-TM">
              <img src={AboutDoctor2} className="card-img-top" alt="" />
              <div className="card-body">
                <h4><b>Dr. Vaishali Tandon</b></h4>
                <p><b>Director </b></p>
              </div>
            </div>
          </div>
          
          
          <div ref={card3Ref} className={`col-lg-4 col-md-6 mb-4 card-TM-container ${card3InView ? 'animate' : ''}`}>
            <div className="card-TM">
              <img src={AboutDoctor3} className="card-img-top" alt="Dr. Shweta Goswami" />
              <div className="card-body">
                <h4><b>Dr. Vaishali Tandon</b></h4>
                <p><b>Director</b></p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      
    </div>
    
  );
};

export default TeamMember;
