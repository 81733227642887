import React from 'react'
import FemaleInfertility2Banner from './FemaleInfertility2Banner'
import FemaleInfertility2Page from './FemaleInfertility2Page'

const FemaleInfertility2 = () => {
  return (
    <div>
      <FemaleInfertility2Banner/><br /><br />
      <FemaleInfertility2Page/><br /><br /><br />
    </div>
  )
}

export default FemaleInfertility2
