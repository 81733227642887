import React, {  useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import FemaleInfertility2 from "../components/img/FemaleInfertility2.jpg";

const FemaleInfertility2Page = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  
  useEffect(() => {
    if (inView) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: { type: 'spring', stiffness: 85, duration: 2},
      });
    }
  }, [controls, inView]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <motion.img 
            src={FemaleInfertility2} 
            className="img-fluid" 
            alt="Your Image" 
            initial={{ x: '-100vw' }}
            animate={controls}
            ref={ref}
          />
        </div>
        <div className="col-md-6">
          <motion.div 
            className="content"
            initial={{ opacity: 0 }}
            animate={controls}
            ref={ref}
          >
            <br />
            {/* <p className='text-start ms-2'>WE OFFERS HOLISTIC & INTEGRATED TREATMENTS</p> */}
            <h1 className='text-start ms-2'>Female Infertility</h1>
            <div className="yellow-line ms-2"></div> {/* Yellow underline */} <br />

            <p className='text-start ms-2'>Female infertility is the inability to conceive within one year with regular, unprotected intercourse. It is mostly caused by issues such as ovulation disorders like polycystic ovary syndrome, damage to the fallopian tubes, problems with the uterus, hormonal imbalance, and advanced age, which causes the number and quality of the eggs to diminish. Other influencing factors could be cigarettes, excessive alcohol consumption, obesity, and stress. Diagnosis involves ovulation tests, imaging, and hormone evaluations; treatment options may include medications, surgical intervention, assisted reproductive technologies like IVF, or even making lifestyle changes, all to improve reproductive health.</p>
            {/* <p className='text-start ms-2'>Located in the prominent location of Noida, we are committed to providing compassionate and personalised fertility treatments.</p> */}
            {/* <button type="button" className="btn btn-warning ms-2">About Us</button> */}
            {/* Add more content here */}
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default FemaleInfertility2Page;
