import React from 'react';
import './FertilitySection.css';
import { useInView } from 'react-intersection-observer';
import imgfertility from "./img/imgfertility.webp";
import women from "./img/women.webp";

const FertilitySection = () => {
  const { ref: box1Ref, inView: box1InView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: box2Ref, inView: box2InView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className="container">
      <div ref={box1Ref} className={`box ${box1InView ? 'animate' : ''}`}>
        <div className="vc_column-inner vc_custom_1702970911176">
          <div className="wpb_wrapper">
            <div className="wpb_single_image wpb_content_element vc_align_center">
              <figure className="wpb_wrapper vc_figure">
                <div className="vc_single_image-wrapper vc_box_border_grey">
                  <img
                    decoding="async"
                    className="vc_single_image-img"
                    src={imgfertility}
                    alt="fertility"
                    title="fertility"
                    loading="lazy"
                  />
                </div>
              </figure>
            </div>
            <div className="wpb_text_column wpb_content_element">
              <div className="wpb_wrapper">
                <h3 className="entry-title" style={{ textAlign: 'center' }}><b>InFertility</b></h3>
                <p className='text-start ms-4' style={{ textAlign: 'center' }}>
                  At a success rate of 60-65% in IVF Treatment, we are also treating patients with our wide range of other infertility treatments like I.C.S.I, Embryo Cryopreservation, Egg Donation, Surrogacy, IUI, and more.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div ref={box2Ref} className={`box ${box2InView ? 'animate' : ''}`}>
        <div className="vc_column-inner vc_custom_1702970911176">
          <div className="wpb_wrapper">
            <div className="wpb_single_image wpb_content_element vc_align_center">
              <figure className="wpb_wrapper vc_figure">
                <div className="vc_single_image-wrapper vc_box_border_grey">
                  <img
                    decoding="async"
                    className="vc_single_image-img"
                    src={women}
                    alt="fertility"
                    title="fertility"
                    loading="lazy"
                  />
                </div>
              </figure>
            </div>
            <div className="wpb_text_column wpb_content_element">
              <div className="wpb_wrapper">
                <h3 className="entry-title" style={{ textAlign: 'center' }}><b>Women Care</b></h3>
                <p className='text-start ms-4' style={{ textAlign: 'center' }}>
                  At a success rate of 60-65% in IVF Treatment, we are also treating patients with our wide range of other infertility treatments like I.C.S.I, Embryo Cryopreservation, Egg Donation, Surrogacy, IUI, and more.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FertilitySection;
