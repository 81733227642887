// src/BlogPage.js
import React, { useEffect } from 'react';
import './BlogPage.css';

const blogs = [
  {
    title: 'Blog Post Title 1',
    description: 'Brief description of blog post 1...',
    image: 'https://via.placeholder.com/300'
  },
  {
    title: 'Blog Post Title 2',
    description: 'Brief description of blog post 2...',
    image: 'https://via.placeholder.com/300'
  },
  {
    title: 'Blog Post Title 3',
    description: 'Brief description of blog post 3...',
    image: 'https://via.placeholder.com/300'
  },
  {
    title: 'Blog Post Title 4',
    description: 'Brief description of blog post 3...',
    image: 'https://via.placeholder.com/300'
  },
  {
    title: 'Blog Post Title 5',
    description: 'Brief description of blog post 3...',
    image: 'https://via.placeholder.com/300'
  },
  {
    title: 'Blog Post Title 6',
    description: 'Brief description of blog post 3...',
    image: 'https://via.placeholder.com/300'
  },
  {
    title: 'Blog Post Title 7',
    description: 'Brief description of blog post 3...',
    image: 'https://via.placeholder.com/300'
  },
  {
    title: 'Blog Post Title 8',
    description: 'Brief description of blog post 3...',
    image: 'https://via.placeholder.com/300'
  },
  {
    title: 'Blog Post Title 9',
    description: 'Brief description of blog post 3...',
    image: 'https://via.placeholder.com/300'
  }
];

const BlogPage = () => {
  useEffect(() => {
    const blogs = document.querySelectorAll('.blog');

    const scrollHandler = () => {
      blogs.forEach(blog => {
        const blogTop = blog.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (blogTop < windowHeight * 0.75) {
          blog.classList.add('animate');
        }
      });
    };

    window.addEventListener('scroll', scrollHandler);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return (
    <div className="blog-page">
      <h1><b>FERTILITY BLOGS</b></h1>
      <div className="blogs-container">
        {blogs.map((blog, index) => (
          <div key={index} className="blog">
            <div className="blog-image-container">
              <img src={blog.image} alt={blog.title} className="blog-image" />
            </div>
            <div className="blog-content">
              <h2 className="blog-title">{blog.title}</h2>
              <p className="blog-description">{blog.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogPage;
