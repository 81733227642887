import React from 'react'
import './Center.css';
const Center = () => {
  return (
    <div>
       <div className="vc_column-inner">
  <div className="wpb_wrapper">
    <div
      id="la_divider664b40df242d6"
      className="js-el la-divider la-unit-responsive"
      data-la_component="UnitResponsive"
      data-el_target="#la_divider664b40df242d6"
      data-el_media_sizes='{"padding-top":"lg:5px;"}'
    />
    <div className="wpb_text_column wpb_content_element  head-uppercase">
      <div className="wpb_wrapper text-start px-5 py-5">
        <h1>Dr. Vaishali Tandon's InFertility Clinic – Best IVF Center in Agra</h1>
        <p>
        
Welcome to Dr. Vaishali Tandon's InFertility Clinic located in , Agra. Under the expert guidance of Dr. Vaishali Tandon, with over 20 years of experience, we provide exceptional IVF and Infertility care. Our treatments are personalized, keeping the individual needs of our patients a priority at all times. <br /><br />

At Dr. Vaishali Tandon's Clinic, there is a supportive and caring staff available 24 hours a day. Trust Dr. Vaishali Tandon's Clinic for the best IVF and infertility treatments in Agra.
        </p>
      </div>
    </div>
    <div className="wpb_text_column wpb_content_element  head-uppercase">
      <div className="wpb_wrapper text-start px-5 py-1">
        <h2>Dr. Vaishali Tandon – Leading IVF Specialist in Agra</h2>
        <p>
        Dr. Vaishali Tandon, MBBS, MD in Obstetrics and Gynaecology, having an experience of over 20 years, is a well-known IVF expert situated in Agra. She specializes in areas related to IVF, ICSI, Donor Egg IVF, Surrogacy, Surgical Sperm Collection, Hysteroscopy, Laparoscopy, Male Infertility, and more, with a focus on providing advanced infertility solutions.
        </p>
        <p>
        Dr. Tandon practices at Dr. Kamalesh Tandon Hospital, located at 4/48 B, Lajpat Kunj, Khandari, Agra, Uttar Pradesh 282002. She is dedicated to making every patient understand and explore different infertility options to find the best-suited treatment. For patients, Dr. Vaishali Tandon is the name that gives them trust, better care, and the best IVF treatments in Agra.

        </p>
      </div>
    </div>
    <div
      id="la_divider664b40df24484"
      className="js-el la-divider la-unit-responsive"
      data-la_component="UnitResponsive"
      data-el_target="#la_divider664b40df24484"
      data-el_media_sizes='{"padding-top":"lg:20px;"}'
    />
    
  </div>
  
</div>
<br /><br /><br /><br />
    </div>
    
    
  )
}

export default Center
