import React from 'react'
import TestimonialsBanner from './TestimonialsBanner'

const Testimonials = () => {
  return (
    <div>
      <TestimonialsBanner/>
    </div>
  )
}

export default Testimonials
