import React from 'react'
import FertilityEnhancingSurgeriesBanner from './FertilityEnhancingSurgeriesBanner'
import FertilityEnhancingSurgeriesPage from './FertilityEnhancingSurgeriesPage'

const FertilityEnhancingSurgeries = () => {
  return (
    <div>
      <FertilityEnhancingSurgeriesBanner/><br /><br />
      <FertilityEnhancingSurgeriesPage/><br /><br /><br />
    </div>
  )
}

export default FertilityEnhancingSurgeries
