import React from 'react'
import OverianCystBanner from './OverianCystBanner'
import OverianCystPage from './OverianCystPage'

const OverianCyst = () => {
  return (
    <div>
      <OverianCystBanner/><br /><br />
      <OverianCystPage/><br /><br /><br />
    </div>
  )
}

export default OverianCyst
