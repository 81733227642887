import React from 'react'
import VideosBanner from './VideosBanner'
import VideoPage from './VideoPage'

const Videos = () => {
  return (
 <div>
  <VideosBanner/>
  <VideoPage/>
 </div>
  )
}

export default Videos
