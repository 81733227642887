import React, {  useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Fallopian from "../components/img/Fallopian.webp";

const TubeBlockPage = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  
  useEffect(() => {
    if (inView) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: { type: 'spring', stiffness: 85, duration: 2},
      });
    }
  }, [controls, inView]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <motion.img 
            src={Fallopian} 
            className="img-fluid" 
            alt="Your Image" 
            initial={{ x: '-100vw' }}
            animate={controls}
            ref={ref}
          />
        </div>
        <div className="col-md-6">
          <motion.div 
            className="content"
            initial={{ opacity: 0 }}
            animate={controls}
            ref={ref}
          >
            <br />
            {/* <p className='text-start ms-2'>WE OFFERS HOLISTIC & INTEGRATED TREATMENTS</p> */}
            <h1 className='text-start ms-2' style={{ fontSize: '1.5rem' }}>Fallopian Tube Block/Tubal Block</h1>
            <div className="yellow-line ms-2"></div> {/* Yellow underline */} <br />

            <p className='text-start ms-2'>Fallopian tube blockage, also known as a blocked fallopian tube or tubal occlusion, occurs when the tubes are physically blocked, hindering an egg's passage from the ovaries to the uterus. This may be caused by a couple of factors, one of which is pelvic inflammatory disease or even sexually transmitted diseases such as chlamydia and gonorrhea; endometriosis; scarring from previous surgeries; or ectopic pregnancies. While some women may have symptoms like infertility or chronic pelvic pain, most of the cases of fallopian tube obstruction are asymptomatic. Imaging tests, such as HSG or laparoscopy, often make a diagnosis possible. Treatments are mostly based on the cause and extent of the block.

</p>
            {/* <p className='text-start ms-2'>Located in the prominent location of Noida, we are committed to providing compassionate and personalised fertility treatments.</p> */}
            {/* <button type="button" className="btn btn-warning ms-2">About Us</button> */}
            {/* Add more content here */}
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default TubeBlockPage;
