import React from 'react'
import "./HomeReview.css";
import Googleimage from "../components/img/Googleimage.png";
import Youtubeimage from "../components/img/Youtubeimage.jpg";
import Facebookimage from "../components/img/Facebookimage.png";
import Instaimage from "../components/img/Instaimage.png";

const HomeReview = () => {
  return (
    <div>
      <div className="app-container">
      <div className="card">
        <a href="https://www.google.com/search?kgmid=/g/11vp_f5w43&hl=en-IN&q=Dr.+Vaishali+Tandon&kgs=0b01d5c6f42a5fb0&shndl=30&shem=lrnole,ssic&source=sh/x/loc/osrp/m5/4#lrd=0x3974779af6cb7cef:0xe02e9fe2e36d2145,1,,,," target="_blank" rel="noopener noreferrer"><br /><img src={Googleimage} alt="Google Logo" /></a>
        <div className="rating">4.8/5</div>
        <div className="count">38 REVIEWS</div>
      </div>
      <div className="card">
        <a href="https://www.youtube.com/@dr.vaishalitandon-oz6of" target="_blank" rel="noopener noreferrer"><img src={Youtubeimage} alt="Youtube Logo" /></a>
        <div className="rating">Dr. Vaishali Tandon</div> <br />
        {/* <div className="count">08 PATIENT STORIES</div> */}
      </div>
      <div className="card">
        <a href="https://www.facebook.com/people/Dr-Vaishali-Tandon/61555988218332/?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer"><img src={Facebookimage} alt="Facebook Logo" /></a>
        <div className="rating">Dr. Vaishali Tandon</div>
        {/* <div className="count">32,439 LIKES</div> */}
      </div>
      <div className="card"><br />
        <a href="https://www.instagram.com/accounts/login/?next=https%3A%2F%2Fwww.instagram.com%2Fdrvaishalitandon%2F%3Figsh%3DMWxudnYzcHo4bDZhNQ%253D%253D&is_from_rle" target="_blank" rel="noopener noreferrer"><img src={Instaimage} alt="Instagram Logo" /></a>
        <div className="rating">@drvaishalitandon</div>
        {/* <div className="count">90 VOTES</div> */}
      </div>
    </div>
    
    </div>
  )
}

export default HomeReview
