import React, {  useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Fertility from "../components/img/Fertility.jpg";

const FertilityEnhancingSurgeriesPage = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  
  useEffect(() => {
    if (inView) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: { type: 'spring', stiffness: 85, duration: 2},
      });
    }
  }, [controls, inView]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <motion.img 
            src={Fertility} 
            className="img-fluid" 
            alt="Your Image" 
            initial={{ x: '-100vw' }}
            animate={controls}
            ref={ref}
          />
        </div>
        <div className="col-md-6">
          <motion.div 
            className="content"
            initial={{ opacity: 0 }}
            animate={controls}
            ref={ref}
          >
            <br />
            {/* <p className='text-start ms-2'>WE OFFERS HOLISTIC & INTEGRATED TREATMENTS</p> */}
            <h1 className='text-start ms-2' style={{ fontSize: '1.7rem' }}>InFertility Enhancing Surgeries</h1>

            <div className="yellow-line ms-2"></div> {/* Yellow underline */} <br />

            <p className='text-start ms-2'>Zeeva Clinic offers specialised treatment for IVF and infertility in India and Mauritius. With a vision to set up a world-class facility, this infertility centre was co-founded by Dr. Shweta Goswami. Under the guidance of the best doctors and surgeons, we use cutting-edge technology resulting in advanced treatment options and the highest level of service and treatment standards.</p>
            <p className='text-start ms-2'>Located in the prominent location of Noida, we are committed to providing compassionate and personalised infertility treatments.</p>
            {/* <button type="button" className="btn btn-warning ms-2">About Us</button> */}
            {/* Add more content here */}
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default FertilityEnhancingSurgeriesPage;
