import React from 'react'
import TubeBlockBanner from './TubeBlockBanner'
import TubeBlockPage from './TubeBlockPage'

const TubeBlock = () => {
  return (
    <div>
      <TubeBlockBanner/><br /><br />
      <TubeBlockPage/><br /><br /><br />
    </div>
  )
}

export default TubeBlock
