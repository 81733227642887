import React, {  useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import OvarianCyst from "../components/img/OvarianCyst.jpeg";

const OverianCystPage = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  
  useEffect(() => {
    if (inView) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: { type: 'spring', stiffness: 85, duration: 2},
      });
    }
  }, [controls, inView]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <motion.img 
            src={OvarianCyst}
            className="img-fluid" 
            alt="Your Image" 
            initial={{ x: '-100vw' }}
            animate={controls}
            ref={ref}
          />
        </div>
        <div className="col-md-6">
          <motion.div 
            className="content"
            initial={{ opacity: 0 }}
            animate={controls}
            ref={ref}
          >
            <br />
            {/* <p className='text-start ms-2'>WE OFFERS HOLISTIC & INTEGRATED TREATMENTS</p> */}
            <h1 className='text-start ms-2'>Ovarian Cyst</h1>
            <div className="yellow-line ms-2"></div> {/* Yellow underline */} <br />

            <p className='text-start ms-2'>An ovarian cyst is filled with fluid and usually forms on or inside an ovary. Most ovarian cysts are benign and are often a normal part of the menstrual cycle. Some cases of functional cysts, such as follicular and corpus luteum cysts, resolve by themselves without intervention. However, there are some that are more complicated—namely, dermoid cysts, endometriomas, and cystadenomas—requiring medical intervention. Symptoms of ovarian cysts include abdominal pain, bloating, and an irregular period, but many go asymptomatic and incidentally detected in routine pelvic examinations or imaging studies.</p>
            {/* <p className='text-start ms-2'>Located in the prominent location of Noida, we are committed to providing compassionate and personalised fertility treatments.</p> */}
            {/* <button type="button" className="btn btn-warning ms-2">About Us</button> */}
            {/* Add more content here */}
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default OverianCystPage;
