import React from 'react'
import InVitroFertilizationBanner from './InVitroFertilizationBanner'
import InVitroFertilizationPage from './InVitroFertilizationPage'

const InVitroFertilization = () => {
  return (
    <div>
     <InVitroFertilizationBanner/><br /><br />
     <InVitroFertilizationPage/><br /><br /><br />
     
    </div>
  )
}

export default InVitroFertilization
