import React from 'react'
import IvfFailuresBanner from './IvfFailuresBanner'
import IvfFailuresPage from './IvfFailuresPage'

const IvfFailures = () => {
  return (
    <div>
      <IvfFailuresBanner/><br /><br />
      <IvfFailuresPage/><br /><br /><br />
    </div>
  )
}

export default IvfFailures
