import React, {  useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Cryopreservation from "../components/img/Cryopreservation.png";

const CryopreservationPage = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  
  useEffect(() => {
    if (inView) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: { type: 'spring', stiffness: 85, duration: 2},
      });
    }
  }, [controls, inView]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <motion.img 
            src={Cryopreservation} 
            className="img-fluid" 
            alt="Your Image" 
            initial={{ x: '-100vw' }}
            animate={controls}
            ref={ref}
          />
        </div>
        <div className="col-md-6">
          <motion.div 
            className="content"
            initial={{ opacity: 0 }}
            animate={controls}
            ref={ref}
          >
            <br />
            {/* <p className='text-start ms-2'>WE OFFERS HOLISTIC & INTEGRATED TREATMENTS</p> */}
            <h1 className='text-start ms-2'>InFertility Cryopreservation</h1>
            <div className="yellow-line ms-2"></div> {/* Yellow underline */} <br />

            <p className='text-start ms-2'>InFertility cryopreservation refers to the process of preserving certain reproductive cells at extremely low temperatures for later use in assisted reproduction. In other words, it is a way of preserving additional reproductive cells obtained from ovaries by stimulating them to produce several eggs, retrieving them, freezing, and storing them. This is often the choice of women who would like to defer childbearing in order to pursue a career or other goals, or for those having a medical treatment that might affect infertility. In a similar method, for men, sperm is collected and frozen for future use.</p>
            {/* <p className='text-start ms-2'>Located in the prominent location of Noida, we are committed to providing compassionate and personalised fertility treatments.</p> */}
            {/* <button type="button" className="btn btn-warning ms-2">About Us</button> */}
            {/* Add more content here */}
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default CryopreservationPage;
