import React, {  useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import AdvancedAge from "../components/img/AdvancedAge.jpeg"; 

const AdvancedAgePage = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  
  useEffect(() => {
    if (inView) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: { type: 'spring', stiffness: 85, duration: 2},
      });
    }
  }, [controls, inView]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <motion.img 
            src={AdvancedAge} 
            className="img-fluid" 
            alt="Your Image" 
            initial={{ x: '-100vw' }}
            animate={controls}
            ref={ref}
          />
        </div>
        <div className="col-md-6">
          <motion.div 
            className="content"
            initial={{ opacity: 0 }}
            animate={controls}
            ref={ref}
          >
            <br />
            {/* <p className='text-start ms-2'>WE OFFERS HOLISTIC & INTEGRATED TREATMENTS</p> */}
            <h1 className='text-start ms-2'>Advanced Age</h1>
            <div className="yellow-line ms-2"></div> {/* Yellow underline */} <br />

            <p className='text-start ms-2'>Advanced-age infertility, or age-related infertility, concerns a progressive, age-related diminution in the ability of a woman to conceive and carry a pregnancy to term, usually beyond the age of 35 years. A woman's infertility decreases with advancing age due to a number of factors that include a decrease in quantity and quality of eggs and changes in the hormone levels, coupled with an augmentation in a variety of medical risks that may affect infertility, such as endometriosis and fibroids. Advanced age infertility can further increase the risk of pregnancy complications, such as miscarriage, chromosomal abnormalities like Down syndrome, or even some cases of gestational diabetes.</p>
            {/* <p className='text-start ms-2'>Located in the prominent location of Noida, we are committed to providing compassionate and personalised fertility treatments.</p> */}
            {/* <button type="button" className="btn btn-warning ms-2">About Us</button> */}
            {/* Add more content here */}
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default AdvancedAgePage;
