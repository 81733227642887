import React, {  useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import PCOS from "../components/img/PCOS.jpg";

const PcosPage = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  
  useEffect(() => {
    if (inView) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: { type: 'spring', stiffness: 85, duration: 2},
      });
    }
  }, [controls, inView]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <motion.img 
            src={PCOS} 
            className="img-fluid" 
            alt="Your Image" 
            initial={{ x: '-100vw' }}
            animate={controls}
            ref={ref}
          />
        </div>
        <div className="col-md-6">
          <motion.div 
            className="content"
            initial={{ opacity: 0 }}
            animate={controls}
            ref={ref}
          >
            <br />
            {/* <p className='text-start ms-2'>WE OFFERS HOLISTIC & INTEGRATED TREATMENTS</p> */}
            <h1 className='text-start ms-2'>PCOS</h1>
            <div className="yellow-line ms-2"></div> {/* Yellow underline */} <br />

            <p className='text-start ms-2'>Polycystic ovarian syndrome, or PCOS, represents one of the most common endocrine disorders affecting women of childbearing age. This pathology is characterized by menstrual irregularities, high levels of androgens resulting in unwanted hair growth and acne, and polycystic ovaries containing many small cysts. A wide range of women affected with PCOS has an underlying defect in insulin resistance, which leads to weight gain and increases the susceptibility of developing diabetes type 2. The cause, however, remains unknown, but it is believed to be caused by a combination of genetic and environmental factors. Treatment usually features the management of symptoms with some lifestyle adjustments, drugs working on regularizing menstrual cycles and hormones.
            </p>
            {/* <p className='text-start ms-2'>Located in the prominent location of Noida, we are committed to providing compassionate and personalised fertility treatments.</p> */}
            {/* <button type="button" className="btn btn-warning ms-2">About Us</button> */}
            {/* Add more content here */}
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default PcosPage;
