import React, {  useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Male from "../components/img/Male.webp";

const MaleInfertilityPage = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  
  useEffect(() => {
    if (inView) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: { type: 'spring', stiffness: 85, duration: 2},
      });
    }
  }, [controls, inView]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <motion.img 
            src={Male} 
            className="img-fluid" 
            alt="Your Image" 
            initial={{ x: '-100vw' }}
            animate={controls}
            ref={ref}
          />
        </div>
        <div className="col-md-6">
          <motion.div 
            className="content"
            initial={{ opacity: 0 }}
            animate={controls}
            ref={ref}
          >
            <br />
            {/* <p className='text-start ms-2'>WE OFFERS HOLISTIC & INTEGRATED TREATMENTS</p> */}
            <h1 className='text-start ms-2'>Male Infertility</h1>
            <div className="yellow-line ms-2"></div> {/* Yellow underline */} <br />

            <p className='text-start ms-2'>Male infertility is a condition whereby a man is unable to father. It could be due to the count of sperms, the quality of the sperms, or blockages that prevent sperms from getting out. Genetic problems, hormonal imbalances, and disorders in health can cause it. Also, infections, poison exposure, smoking tobacco, and too much alcohol consumption are other contributors.</p>
            <p className='text-start ms-2'>Diagnosis comprises physical examinations, semen analysis, and hormone tests. Treatments categorically depend on the cause and may involve medication, lifestyle changes, surgery, or indeed assisted reproduction techniques such as IVF or even IUI.</p>
            {/* <button type="button" className="btn btn-warning ms-2">About Us</button> */}
            {/* Add more content here */}
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default MaleInfertilityPage;
