import React from 'react'
import Endobanner from "../components/img/Endobanner.jpg";
const EndometriosisBanner = () => {
  return (
    <div>
    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={Endobanner} className="d-block w-100 carousel-image" alt="..." />
        </div>
      </div>
    </div>
  </div>
  )
}

export default EndometriosisBanner
