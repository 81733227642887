import React, {  useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import HighRisk from "../components/img/HighRisk.jpg";

const HighRiskPregnancyPage = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  
  useEffect(() => {
    if (inView) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: { type: 'spring', stiffness: 85, duration: 2},
      });
    }
  }, [controls, inView]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <motion.img 
            src={HighRisk} 
            className="img-fluid" 
            alt="Your Image" 
            initial={{ x: '-100vw' }}
            animate={controls}
            ref={ref}
          />
        </div>
        <div className="col-md-6">
          <motion.div 
            className="content"
            initial={{ opacity: 0 }}
            animate={controls}
            ref={ref}
          >
            <br />
            {/* <p className='text-start ms-2'>WE OFFERS HOLISTIC & INTEGRATED TREATMENTS</p> */}
            <h1 className='text-start ms-2'>High Risk Pregnancy</h1>
            <div className="yellow-line ms-2"></div> {/* Yellow underline */} <br />

            <p className='text-start ms-2'>High-risk pregnancy is one in which either the mother, the baby, or both are susceptible to complications. This can be because of the mother being at either end of the age spectrum: very young or over 35 years with a history of preexisting medical conditions like diabetes or uncontrolled high blood pressure, or previous pregnancy complications; it could also be because of multiple pregnancies, smoking, or substance abuse.</p>
            <p className='text-start ms-2'>Those pregnancies demand higher control and specialized care from health career providers in order to optimize the outcome for both mother and baby.</p>
            {/* <button type="button" className="btn btn-warning ms-2">About Us</button> */}
            {/* Add more content here */}
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default HighRiskPregnancyPage;
