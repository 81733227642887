import React from 'react'
import Thirdpartybanner from "../components/img/Thirdpartybanner.jpg";
const ThirdPartyReproductionBanner = () => {
  return (
    <div>
    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={Thirdpartybanner} className="d-block w-100 carousel-image" alt="..." />
        </div>
      </div>
    </div>
  </div>
  )
}

export default ThirdPartyReproductionBanner
