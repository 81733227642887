import React from 'react'
import FemaleInfertilityBanner from './FemaleInfertilityBanner'
import FemaleInfertilityPage from './FemaleInfertilityPage'

const FemaleInfertility = () => {
  return (
    <div>
      <FemaleInfertilityBanner/><br /><br />
      <FemaleInfertilityPage/><br /><br /><br />
    </div>
  )
}

export default FemaleInfertility
