import './App.css';
import Footer from './components/Footer';

import Navbar from './components/Navbar';
import Whatsapp from './components/Whatsapp';







function App() {
  return (
    <div className="App">
      
  
      
    <Navbar/>
    
    <Whatsapp/>
    <Footer/>
    </div>
  );
}

export default App;
