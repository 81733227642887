import React from 'react'
import BlogPage from './BlogPage'
import BlogBanner from './BlogBanner'

const Blogs = () => {
  return (
    <div>
       <BlogBanner/>
      <BlogPage/><br /><br />
     
    </div>
  )
}

export default Blogs
