import React, {  useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Ovulation from "../components/img/Ovulation.jpg"

const OvulationInductionPage = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  
  useEffect(() => {
    if (inView) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: { type: 'spring', stiffness: 85, duration: 2},
      });
    }
  }, [controls, inView]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <motion.img 
            src={Ovulation} 
            className="img-fluid" 
            alt="Your Image" 
            initial={{ x: '-100vw' }}
            animate={controls}
            ref={ref}
          />
        </div>
        <div className="col-md-6">
          <motion.div 
            className="content"
            initial={{ opacity: 0 }}
            animate={controls}
            ref={ref}
          >
            <br />
            {/* <p className='text-start ms-2'>WE OFFERS HOLISTIC & INTEGRATED TREATMENTS</p> */}
            <h1 className='text-start ms-2'>Ovulation Induction</h1>
            <div className="yellow-line ms-2"></div> {/* Yellow underline */} <br />

            <p className='text-start ms-2'>Ovulation induction is a term used for medication given to women who ovulate irregularly or never ovulate to produce and release an egg. Examples of medications include clomiphene citrate or letrozole.</p>
            <p className='text-start ms-2'>This would increase the chances of becoming pregnant, either naturally or through treatments like IUI or in vitro fertilization. Doctors track hormones and ovaries to note the best time for the release of the eggs, so that fertilization can more likely occur.</p>
            {/* <button type="button" className="btn btn-warning ms-2">About Us</button> */}
            {/* Add more content here */}
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default OvulationInductionPage;
