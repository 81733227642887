import React from 'react'
import HighRiskPregnancyBanner from './HighRiskPregnancyBanner'
import HighRiskPregnancyPage from './HighRiskPregnancyPage'

const HighRiskPregnancy = () => {
  return (
    <div>
      <HighRiskPregnancyBanner/><br /><br />
      <HighRiskPregnancyPage/><br /><br /><br />
    </div>
  )
}

export default HighRiskPregnancy
