import React from 'react'
import EndometriosisBanner from './EndometriosisBanner'
import EndometriosisPage from './EndometriosisPage'

const Endometriosis = () => {
  return (
    <div>
       <EndometriosisBanner/><br /><br />
       <EndometriosisPage/><br /><br /><br />
    </div>
  )
}

export default Endometriosis
