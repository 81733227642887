import React from 'react'
import PcosBanner from './PcosBanner'
import PcosPage from './PcosPage'

const Pcos = () => {
  return (
    <div>
      <PcosBanner/><br /><br />
      <PcosPage/><br /><br /><br />
    </div>
  )
}

export default Pcos
