import React, {  useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Endometriosis from "../components/img/Endometriosis.jpg";

const EndometriosisPage = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  
  useEffect(() => {
    if (inView) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: { type: 'spring', stiffness: 85, duration: 2},
      });
    }
  }, [controls, inView]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <motion.img 
            src={Endometriosis} 
            className="img-fluid" 
            alt="Your Image" 
            initial={{ x: '-100vw' }}
            animate={controls}
            ref={ref}
          />
        </div>
        <div className="col-md-6">
          <motion.div 
            className="content"
            initial={{ opacity: 0 }}
            animate={controls}
            ref={ref}
          >
            <br />
            {/* <p className='text-start ms-2'>WE OFFERS HOLISTIC & INTEGRATED TREATMENTS</p> */}
            <h1 className='text-start ms-2'>Endometriosis/Adenomyosis</h1>
            <div className="yellow-line ms-2"></div> {/* Yellow underline */} <br />

            <p className='text-start ms-2'>Endometriosis is a condition in which tissue closely similar to the lining inside the uterus grows outside the uterus, causing painfulness with swelling, at times infertility. Adenomyosis is when endometrial tissue grows into the muscular wall of the uterus and is regarded as quite close to endometriosis. Both cause very painful menstrual cramps, heavy bleeding, and chronic pelvic pain. Hence, though the primary causes of the diseases are not clearly known, possible factors for endometriosis and adenomyosis include retrograde menstruation, hormonal imbalance, disorders in the immune system, and genetic predisposition.</p>
            {/* <p className='text-start ms-2'>Located in the prominent location of Noida, we are committed to providing compassionate and personalised fertility treatments.</p> */}
            {/* <button type="button" className="btn btn-warning ms-2">About Us</button> */}
            {/* Add more content here */}
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default EndometriosisPage;
